import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  // This controller is a workaround found in https://discuss.hotwired.dev/t/video-tags-loaded-via-turbo-drive/2746/4
  // that must be used until a version Safari is released that fixes the
  // following bug: https://bugs.webkit.org/show_bug.cgi?id=222657 which
  // causes the ShadowDOM elements (like video controls) to not always be
  // rendered when added to the DOM tree by TurboDrive
  //
  // Safari 16.3 still exhibits this issue.
  initialize() {
    this.element.replaceWith(this.element.content)
  }
}
