import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.element.style.visibility = "hidden"
    let animationClassList = [...this.element.classList].filter(c => c.startsWith("animate__"))
    DOMTokenList.prototype.remove.apply(this.element.classList, animationClassList)

    document.addEventListener('aos:in', (e) => {
      if (e.detail.contains(this.element)) {
        setTimeout(() => {
          this.element.style.visibility = "visible"
          DOMTokenList.prototype.add.apply(this.element.classList, animationClassList)
        }, parseInt(e.detail.dataset.aosDuration) || 1000)
      }
    });
  }
}
