import { Controller } from "@hotwired/stimulus"
import mediumZoom from 'medium-zoom'

export default class extends Controller {
  static targets = [
    "toc",
    "tocContent",
  ]

  static values = {
    tableSelector: String,
    contentSelector: String,
    headingsOffset: Number,
    headingSelector: String,
  }

  connect() {
    mediumZoom('[data-zoomable]')

    if (this.hasTocTarget) {
      this.buildToc();
      setTimeout(()=> {
        this.syncTocScroll();
      }, 400)
    }
  }

  disconnect() {
    if (this.hasTocTarget) {
      tocbot.destroy();
    }
  }

  buildToc() {
    tocbot.init({
      // Where to render the table of contents.
      tocSelector: this.tableSelectorValue,
      // Where to grab the headings to build the table of contents.
      contentSelector: this.contentSelectorValue,
      scrollSmooth: false,
      headingsOffset: this.headingsOffsetValue || 1,
      // Which headings to grab inside of the contentSelector element.
      headingSelector: this.headingSelectorValue || 'h1',
      disableTocScrollSync: true
    });
    if (this.tocContentTarget.querySelectorAll("li").length < 3) {
      this.tocTarget.style.display = "none"
      this.tocContentTarget.style.display = "none"
    } else {
      this.tocTarget.style.display = ""
      this.tocContentTarget.style.display = ""
    }
  }

  syncTocScroll() {
    let element = document.querySelector(this.tableSelectorValue)?.querySelector('.is-active-li')
    if (element && element.getBoundingClientRect().top > this.tocContentTarget.getBoundingClientRect().bottom) {
      this.tocContentTarget.scrollTop = element.offsetTop
    } else if (element && this.tocContentTarget.getBoundingClientRect().top > element.getBoundingClientRect().top) {
      this.tocContentTarget.scrollTop = element.offsetTop
    }
  }
}
