import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "loader"]

  static values = {
    id: String,
    formData: Object,
  }

  connect() {
    setTimeout(() => {
      this.initializeAvoma();
    }, 1500); // Avoma does not work unless you wait 500ms before trying to load it
  }

  initializeAvoma() {
    if (Avoma) {
      const initDetails = {
        routerID: this.idValue,
      };
      Avoma.initRouter(initDetails);
      Avoma.triggerRouter(this.formDataValue);
      this.loaderTarget.style.display = "none";
    } else {
      // Avoma is not loaded yet, try again in a few moments
      setTimeout(() => {
        this.initializeAvoma();
      }, 500);
    }
  }
}
