import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    document.addEventListener('aos:in:arrowAffordance', () => {
      this.element.classList.add("animate__animated", "animate__fadeOutUp")
      this.element.addEventListener('animationend', () => {
        this.element.remove()
      })
    });
  }
}
