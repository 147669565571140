import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  badge() {
    return document.querySelector('.grecaptcha-badge');
  }

  showCaptchaBadge(event) {
    this.badge().classList.remove("hidden")
    this.badge().classList.add("visible")
  }

  hideCaptchaBadge(event) {
    this.badge().classList.remove("visible")
    this.badge().classList.add("hidden")
  }
}
