import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "code", "copyButton" ]

  connect() {
    this.copyButtonTarget.setAttribute("data-action", "click->blog--copy-code#copy")
  }

  async copy() {
    if (navigator.clipboard) {
      const text = this.codeTarget.querySelector('code').innerText
      await navigator.clipboard.writeText(text)
      this.copyButtonTarget.classList.add('success')

      setTimeout(() => {
        this.copyButtonTarget.classList.remove('success')
      }, 1500)
    }
  }
}
