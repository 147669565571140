import { Controller } from "@hotwired/stimulus"


const platformsMap = {
  'mac': 'macOS',
  'windows': 'Windows',
  'linux': "Linux",
}
export default class extends Controller {
  static targets = [
    'property',
    'chosenFilters',
    'filterToggle',
    'filterDropdown',
    'filterPlatform',
    'filterOverlay',
    'allOption',
    'filter',
    'visibleCount',
    'totalCount'
  ]
  static values = {
    search: String,
    activePlatforms: Array,
    totalCount: Number,
    visibleCount: Number,
    dropdownOpen: Boolean,
  }

  connect() {
    this.totalCountValue = this.propertyTargets.length
    this.visibleCountValue = this.totalCountValue
  }

  search({ target: { value } }) {
    this.searchValue = value
  }

  toggleDropdown() {
    this.dropdownOpenValue = !this.dropdownOpenValue
  }

  selectFilter({ currentTarget: { checked }, params: { filter } }) {
    if (checked) {
      if (filter === 'all') {
        this.activePlatformsValue = ['mac', 'linux', 'windows']
      } else {
        this.activePlatformsValue = [...this.activePlatformsValue, filter]
      }
    } else {
      if (filter === 'all') {
        this.activePlatformsValue = []
      } else {
        this.activePlatformsValue = this.activePlatformsValue.filter(platform => platform !== filter)
      }
    }
  }

  dropdownOpenValueChanged() {
    this.filterDropdownTarget.style.display = this.dropdownOpenValue ? 'block' : 'none'
    this.filterOverlayTarget.style.display = this.dropdownOpenValue ? 'block' : 'none'
  }

  activePlatformsValueChanged() {
    this.executeFilter()
    this.alterCheckmarks()
    this.alterSemi()
    this.handleDisplay()
  }

  handleDisplay() {
    if (this.activePlatformsValue.length === 0) {
      this.chosenFiltersTarget.style.display = 'none'
      this.chosenFiltersTarget.textContent = ''
    } else {
      this.chosenFiltersTarget.style.display = 'flex'
      const displayValue = this.isAllFilters(this.activePlatformsValue) ? 'All Platforms' : this.activePlatformsValue.map(platform => platformsMap[platform]).join(', ')
      this.chosenFiltersTarget.textContent = displayValue
    }
  }

  alterSemi() {
    if (this.activePlatformsValue.length === 0 || this.isAllFilters(this.activePlatformsValue)) {
      this.allOptionTarget.classList.remove('semi')
    } else {
      this.allOptionTarget.classList.add('semi')
    }
  }

  alterCheckmarks() {
    if (this.isAllFilters(this.activePlatformsValue)) {
      this.allOptionTarget.checked = true
      this.filterPlatformTargets.forEach(filter => filter.checked = true)
    } else {
      this.allOptionTarget.checked = false
      this.filterPlatformTargets.forEach(filter => {
        filter.checked = this.activePlatformsValue.includes(filter.id.toLowerCase())
      })
    }
  }

  platformDisplayValue() {
  }

  isAllFilters(platforms) {
    return platforms.includes('mac') && platforms.includes('linux') && platforms.includes('windows')
  }

  searchValueChanged() {
    this.executeFilter()
  }

  totalCountValueChanged() {
    this.totalCountTarget.textContent = this.totalCountValue
  }

  visibleCountValueChanged() {
    this.visibleCountTarget.textContent = this.visibleCountValue
  }

  executeFilter() {
    this.visibleCountValue = 0
    this.propertyTargets.forEach(property => {
      const comparitor = this.searchValue.toLowerCase()
      const hasMatchingName = property.dataset.name.toLowerCase().startsWith(comparitor)

      const tags = property.dataset.tags.toLowerCase().replace(/\s+/g, '').split(',')
      const hasMatchingTag = tags.some(tag => tag.startsWith(comparitor))

      const platforms = property.dataset.platforms.toLowerCase().split(',')
      const hasMatchingPlatform = this.hasMatchingPlatformData(platforms)

      if ((hasMatchingName || hasMatchingTag) && hasMatchingPlatform) {
        property.classList.remove('hidden')
        this.visibleCountValue += 1
      } else {
        property.classList.add('hidden')
      }
    })
  }

  hasMatchingPlatformData(platforms) {
    if (this.activePlatformsValue.length === 0) return true
    if (this.isAllFilters(this.activePlatformsValue)) return this.isAllFilters(platforms)

    return this.activePlatformsValue.every(platform => platforms.includes(platform))
  }
}
