import tippy from 'tippy.js'

let resetTooltips = () => {
  const tooltips = [...document.querySelectorAll('*[data-tippy]')];
  tooltips.forEach(tooltip => {
    tooltip._tippy && tooltip._tippy.destroy();
    let tippyOptions = {
      allowHTML: tooltip.getAttribute('data-tippy-allow-html') ?? false,
      content: tooltip.getAttribute('data-tippy'),
      popperOptions: {
        strategy: 'fixed',
      }
    }
    if (tooltip.getAttribute('data-tippy-append-to-body')) {
      tippyOptions.appendTo = document.body
    }
    tippy(tooltip, tippyOptions)
  });
}

// Turbo Drive Tooltip Handlers
document.addEventListener("turbo:load", () => {
  resetTooltips()
});

document.addEventListener("ajax:complete", () => {
  resetTooltips()
});
