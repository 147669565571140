import { Controller } from "@hotwired/stimulus"
import confetti from 'canvas-confetti';

export default class extends Controller {
  static targets = [
    "background",
  ]

  connect() {
    document.body.style.position = 'fixed';
    document.body.style.top = `-${window.scrollY}px`;
    document.body.style.right = "0";
    document.body.style.left = "0";
    document.body.style.background = "#fff";

    confetti({
      particleCount: 200,
      spread: 170,
      origin: { y: 0.6 }
    });
  }

  teardown() {
    this.close()
  }

  close(event) {
    event?.preventDefault();
    this.backgroundTarget.classList.add('animate__animated', 'animate__fadeOut', 'animate__faster');
    this.backgroundTarget.addEventListener('animationend', () => {
      this.backgroundTarget.remove()
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.right = '';
      document.body.style.left = '';
      document.body.style = '';

      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }, { once: true });
  }
}
