import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["menu", "background"]

  toggle() {
    this.menuTarget.classList.toggle("visible")
    this.backgroundTarget.classList.toggle("visible")
  }

  close() {
    this.menuTarget.classList.remove("visible")
    this.backgroundTarget.classList.remove("visible")
  }

  teardown() {
    this.close()
  }
}
