import { Controller } from "@hotwired/stimulus"
import Mustache from 'mustache';

export default class extends Controller {
  static values = {
    url: String,
    template: String,
  }

  connect() {
    fetch(this.urlValue)
    .then(response => response.json())
    .then(blogPosts => {
      this.element.innerHTML = ""
      blogPosts.forEach(post => {
        let template = Mustache.render(this.templateValue, post)
        this.element.innerHTML = this.element.innerHTML + template;
      });
    });
  }
}
