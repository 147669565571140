import { Controller } from "@hotwired/stimulus"
import mediumZoom from 'medium-zoom'

export default class extends Controller {
  static targets = [
    "toc",
    "tocContent",
    "ctas",
  ]

  static values = {
    tableSelector: String,
    contentSelector: String,
    headingsOffset: Number,
    headingSelector: String,
    ctaScrollTop: { type: Number, default: 80 },
  }

  connect() {
    mediumZoom('[data-zoomable]')
    setTimeout(()=> {
      this.buildToc()
      this.syncTocScroll()
      this.checkCtaVisibility()
    }, 400)
  }

  disconnect() {
    tocbot.destroy()
  }

  checkCtaVisibility() {
    if (this.ctasTarget.getBoundingClientRect().top <= this.ctaScrollTopValue) {
      this.ctasTarget.classList.add("visible")
    } else {
      this.ctasTarget.classList.remove("visible")
    }
  }

  buildToc() {
    tocbot.init({
      // Where to render the table of contents.
      tocSelector: this.tableSelectorValue,
      // Where to grab the headings to build the table of contents.
      contentSelector: this.contentSelectorValue,
      scrollSmooth: false,
      headingsOffset: this.headingsOffsetValue || 1,
      // Which headings to grab inside of the contentSelector element.
      headingSelector: this.headingSelectorValue || 'h1',
      disableTocScrollSync: true
    });
    if (this.tocContentTarget.querySelectorAll("li").length < 3) {
      this.tocTarget.style.display = "none"
      this.tocContentTarget.style.display = "none"
    } else {
      this.tocTarget.style.display = ""
      this.tocContentTarget.style.display = ""
    }
  }

  syncTocScroll() {
    let element = document.querySelector(this.tableSelectorValue)?.querySelector('.is-active-li')
    if (element && element.getBoundingClientRect().top > this.tocContentTarget.getBoundingClientRect().bottom) {
      this.tocContentTarget.scrollTop = element.offsetTop
    } else if (element && this.tocContentTarget.getBoundingClientRect().top > element.getBoundingClientRect().top) {
      this.tocContentTarget.scrollTop = element.offsetTop
    }
  }
}
