import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['container', 'row'];
  static values = { isScrollRunning: Boolean }

  connect() {
    this.isScrollRunningValue = false
    this.scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight
  }

  onScroll() {
    if (!this.isScrollRunningValue) {
      this.isScrollRunningValue = true
    }
  }

  isScrollRunningValueChanged() {
    if (!this.isScrollRunningValue) return

    if (window.requestAnimationFrame) {
      window.requestAnimationFrame(this.scrollIcons.bind(this))
    } else {
      setTimeout(this.scrollIcons.bind(this), 66)
    }
  }

  scrollIcons() {
    const { top, bottom } = this.containerTarget.getBoundingClientRect()
    const { scrollY, innerHeight } = window

    if (top < innerHeight && bottom >= 0) {
      const currentScroll = (scrollY / this.scrollHeight)
      const scrollDistance = currentScroll * 800

      this.rowTargets.forEach((el, i) => {
        el.style.transform = `translateX(${i % 2 ? "-" : ''}${scrollDistance}px)`
      })
    }

    this.isScrollRunningValue = false
  }
}
