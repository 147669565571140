import { Controller } from "@hotwired/stimulus"
import isVisible from "true-visibility"

export default class extends Controller {
  static targets = [
    "check",
    "slackMessage",
    "slackMessageTitle",
    "slackMessageIcon",
    "slackMessageRationale"
  ]

  static values = {
    currentCheck: Number,
  }

  static intervalTimer

  connect() {
    this.loadCheck(this.checkTarget)
    this.setRandomCheckInterval()
  }

  disconnect() {
    clearInterval(this.intervalTimer)
  }

  setRandomCheckInterval() {
    this.intervalTimer = setInterval(() => {
      this.displayRandomCheck()
    }, 10_000)
  }

  displayRandomCheck() {
    const randomizedChecks = this.shuffle(this.visibleChecks())
    let randomCheck = randomizedChecks[0]
    if (randomCheck && randomCheck.dataset.checkId == this.currentCheckValue) {
      randomCheck = randomizedChecks[1]
    }

    if (randomCheck) {
      this.loadCheck(randomCheck)
    }
  }

  visibleChecks() {
    return this.checkTargets.filter(check => isVisible(check))
  }

  displaySelectedCheck(event) {
    clearInterval(this.intervalTimer)
    this.loadCheck(event.currentTarget)
    this.setRandomCheckInterval()
  }

  loadCheck(check) {
    const id = check.dataset.checkId
    const name = check.dataset.checkName
    const imageSrc = check.dataset.checkImageSrc
    const rationale = check.dataset.checkRationaleHtml

    this.checkTargets.forEach((el) => el.classList.remove('selected'));
    check.classList.add("selected")
    this.slackMessageTarget.classList.add('animate__animated', 'animate__fadeOutRight', 'animate__faster');
    this.slackMessageTarget.addEventListener('animationend', () => {
      this.currentCheckValue = id
      this.slackMessageTitleTarget.innerHTML = name
      this.slackMessageRationaleTarget.innerHTML = rationale
      this.slackMessageIconTarget.src = imageSrc
      this.slackMessageTarget.classList.remove('animate__fadeOutRight');
      this.slackMessageTarget.classList.add('animate__fadeInRight');
    }, { once: true });
  }

  shuffle(array) {
    let tmp, current, top = array.length;

    if(top) while(--top) {
        current = Math.floor(Math.random() * (top + 1));
        tmp = array[current];
        array[current] = array[top];
        array[top] = tmp;
    }

    return array;
  }
}
