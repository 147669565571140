import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "background",
    "container",
  ]

  connect() {
    document.body.style.position = 'fixed';
    document.body.style.top = `-${window.scrollY}px`;
    document.body.style.right = "0";
    document.body.style.left = "0";
    document.body.style.background = "#fff";
  }

  teardown() {
    this.close();
  }

  closeUnlessInside(event) {
    if (!this.containerTarget.contains(event.target)) {
      this.close();
    }
  }

  close(event) {
    event?.preventDefault();
    this.backgroundTarget.classList.add('animate__animated', 'animate__fadeOut', 'animate__faster');
    this.backgroundTarget.addEventListener('animationend', () => {
      this.backgroundTarget.remove()
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.right = '';
      document.body.style.left = '';
      document.body.style = '';

      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }, { once: true });
  }

  keyUpHandler(event) {
    switch (event.key) {
      case "Esc":
      case "Escape":
        this.close();
      default:
        return;
    }
  }
}
