import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["menu", "hamburger"]

  open() {
    this.hamburgerTarget.style.display = 'none'
    this.menuTarget.style.display = 'block'
  }

  close() {
    this.hamburgerTarget.style.display = 'block'
    this.menuTarget.style.display = 'none'
  }

  teardown() {
    this.close()
  }
}
