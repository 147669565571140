import smoothscroll from 'smoothscroll-polyfill';
import 'smoothscroll-anchor-polyfill'

smoothscroll.polyfill();

// Turbo Drive scrolls to the top of every page when smooth-scrolling is
// set to on. This code below ensures each page transition immediately scrolls
// to the top and then resets back to the smooth-scroll behavior in case the
// user clicks on an ID anchor tag.
document.addEventListener(`turbo:load`, () => {
  setTimeout(() => {
    document.documentElement.style.scrollBehavior = `smooth`
  }, 500)
})
document.addEventListener(`turbo:before-visit`, () => {
  document.documentElement.style.scrollBehavior = `unset`
})
