import { Controller } from "@hotwired/stimulus"
import confetti from 'canvas-confetti';


export default class extends Controller {
  static targets = [
    'banner',
  ]

  lastDeployed = Date.now() - 5000;

  connect() {
    this.checkBannerVisibility();
  }

  recordCta() {
    localStorage.setItem('bannerHidden', 'true');
  }

  dismiss() {
    localStorage.setItem('bannerHidden', 'true');
    this.bannerTarget.style.display = "none";
  }

  deployConfetti() {
    const now = Date.now();
    if (now - this.lastDeployed > 3000) {
      confetti({
        particleCount: 200,
        angle: 270,
        spread: 170,
        origin: { y: 0 }
      });
      // Update lastDeployed to the current time
      this.lastDeployed = now;
    }
  }

  checkBannerVisibility() {
    if (localStorage.getItem('bannerHidden') === 'true') {
      this.bannerTarget.style.display = "none";
    } else {
      this.bannerTarget.style.display = "";
    }
  }
}
