import { Controller } from "@hotwired/stimulus"
import { CountUp } from 'countup.js';

export default class extends Controller {
  static targets = ["trigger", "counter"]
  static values = {
    finalTotal: Number,
    duration: String,
    event: String,
    aos: Boolean,
    dynamic: Boolean,
    easing: Boolean,
  }

  async connect() {
    this.trigger().addEventListener(
      this.eventValue,
      this.addEventListenerCallback(
        this.counterTarget,
        this.finalTotalValue
      )
    )
  }

  duration(count) {
    const rawDurationValue = parseFloat(this.durationValue)
    if (!rawDurationValue) {
      return 3.9
    }
    if (this.dynamicValue) {
      // the "Duration Value" is now considered to be the time it takes to fill 100% of the chart.
      // We compare this against the count, and get a percentage as a result.
      return (count / 100) * rawDurationValue
    }
    return rawDurationValue

  }

  addEventListenerCallback(element, count) {
    const duration = this.duration(count) || 3.9
    const options = {
      useEasing: this.easingValue || false,
      duration,
    }

    if (this.dynamicValue) {
      this.triggerTarget.style.animationDuration = `${duration}s`
    }

    return () => {
      // AOS sometimes fires multiple events, this ensures the counter is
      // only fired once.
      if (this.element.hasAttribute("data-controller")) {
        this.countUpEl = new CountUp(element, count, options);
        this.countUpEl.start();
        this.element.removeAttribute("data-controller");
      }
    }
  }

  trigger() {
    return this.hasTriggerTarget ? this.triggerTarget : document
  }
}
