import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const graphDefinition = this.element.textContent;
    const cb = (svgGraph) => {
      this.element.outerHTML = svgGraph;
    };
    mermaid.render("mermaid", graphDefinition, cb);
  }
}
