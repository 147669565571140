import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"

import "./global/src/localtime.js"
import "./global/src/tippy.js"
import "./global/listeners/turbo_csp.js"
import "./global/listeners/turbo_hubspot.js"
import "./global/listeners/turbo_scroll_position.js"
import "./global/controllers"
import "./global/src/stream_actions.js"

import "./marketing/listeners"
import "./marketing/controllers"

Rails.start();
