import { Controller } from "@hotwired/stimulus"
import debounce from "lodash/debounce";

export default class extends Controller {
  static targets = [
    "input",
    "results",
  ];

  static values = {
    url: String,
  };

  static classes = [
    "loading",
    "selected",
  ];

  hideResultsUnlessInside(event) {
    if (!this.element.contains(event.target)) {
      this.hideSearchResults();
    }
  }

  teardown() {
    this.hideSearchResults();
  }

  startLoading() {
    this.loaderTimeout = setTimeout(() => {
      this.element.classList.add(this.loadingClass);
    }, 500);
  }

  stopLoading() {
    this.element.classList.remove(this.loadingClass);
    clearTimeout(this.loaderTimeout);
  }

  search = debounce(() => {
    const query = this.inputTarget.value;

    if (this.lastQuery == query && this.resultsTarget.innerHTML != "") return;

    if (query == "") {
      this.hideSearchResults();
      return;
    }

    this.lastQuery = query;
    this.startLoading();

    const url = new URL(this.urlValue);
    url.searchParams.append("q", query);

    fetch(url.href).then((response) => {
      return response.text();
    }).then((html) => {
      clearTimeout();
      this.stopLoading();
      this.resultsTarget.innerHTML = html;
    })
  }, 500);

  get results() {
    if (!this.hasResultsTarget) return [];
    let items = this.resultsTarget.getElementsByClassName("docs__search-results-link");
    return Array.from(items);
  }

  get selectedResultIndex() {
    return this.results.findIndex(item => item.classList.contains(this.selectedClass));
  }

  clearSelection() {
    this.results.forEach(item => {
      item.classList.remove(this.selectedClass);
    });
  }

  hideSearchResults() {
    this.resultsTarget.innerHTML = "";
  }

  navigate(indexDiff = 1) {
    if (this.resultsTarget.innerHTML == "") return;

    const selectedIndex = this.selectedResultIndex;
    const resultLength = this.results.length;
    const newIndex = selectedIndex === -1 ? 0 : selectedIndex + indexDiff;

    if ((selectedIndex === resultLength -1 && indexDiff === 1) || (selectedIndex === 0 && indexDiff === -1)) {
      return;
    }

    this.clearSelection();
    this.results[newIndex].classList.add(this.selectedClass);
  }

  browseToSelectedResult() {
    const selectedResult = this.results[this.selectedResultIndex]
    if (selectedResult == null) return;

    const location = selectedResult.getAttribute("href");
    window.location.href = location;
  }

  navigateWithKeyboard(event) {
    switch (event.key) {
      case "Up": // support Edge/IE
      case "ArrowUp":
        this.navigate(-1);
        event.preventDefault();
        break;
      case "Down": // support Edge/IE
      case "ArrowDown":
        this.navigate(1);
        event.preventDefault();
        break;
      case "Enter":
        this.browseToSelectedResult();
        break;
      case "Esc": // support Edge/IE
      case "Escape":
        this.hideSearchResults();
        break;
      case "/":
        if (document.activeElement !== this.inputTarget) {
          this.inputTarget.focus();
          event.preventDefault();
        }
        break;
      default:
        return;
    }
  }
}
