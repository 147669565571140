import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "section",
    "container",
    "link",
    "popover",
    "content",
    "arrow",
    "background",
    "buttons"
  ]

  dimensions = {
    usecases: { width: 418, height: 212, x: 100 },
    product: { width: 410, height: 212, x: 140 },
    resources: { width: 430, height: 285, x: 180 },
  }

  show(event) {
    let targetPopover = event.target
    setTimeout(() => {
      this.popoverTarget.classList.add('inside-nav');
    }, 100)
    this.showSection(targetPopover);
  }

  hideSlide() {
    this.popoverTarget.classList.remove('open');
    this.sectionTargets.forEach(el => el.classList.remove('active'));
  }

  hide() {
    this.popoverTarget.classList.remove('open');
    this.sectionTargets.forEach(el => el.classList.remove('active'));
    this.popoverTarget.classList.remove('inside-nav');
  }

  showSection(targetPopover, dontReveal = false) {
    const popoverLeft = this.popoverTarget.getBoundingClientRect().x;

    this.linkTargets.forEach((navLink) => {
      let section =  navLink.getAttribute('data-nav');
      let rect = navLink.getBoundingClientRect();
      this.dimensions[section].arrowX = rect.left + (rect.width / 2) - popoverLeft;
    });

    let section = targetPopover.getAttribute('data-nav');
    let buttonStyles = window.getComputedStyle(this.buttonsTarget);
    let marginLeft = parseInt(buttonStyles.getPropertyValue('margin-left'))

    if (!dontReveal) {
      this.popoverTarget.classList.add('open');
    }

    this.sectionTargets.forEach(el => el.classList.remove('active'));
    this.containerTarget.querySelector(`.nav-popover-section-${section}`).classList.add('active');
    // Position arrow
    this.arrowTarget.style.transform = `
      translateX(${this.dimensions[section].arrowX}px)
      rotate(45deg)`;

    // Resize and position background. Hardcoded numbers are the  height/width
    // CSS styles of the background element.
    this.backgroundTarget.style.transform = `
      translateX(${ this.dimensions[section].x + marginLeft }px)
      scaleX(${ this.dimensions[section].width / 430 })
      scaleY(${ this.dimensions[section].height / 212 })
    `;

    // Resize and position content
    this.contentTarget.style.width = this.dimensions[section].width + 'px';
    this.contentTarget.style.height = this.dimensions[section].height + 'px';
    this.contentTarget.style.transform = `translateX(${ this.dimensions[section].x + marginLeft }px)`
  }
}
