import { Controller } from "@hotwired/stimulus"
import VanillaTilt from 'vanilla-tilt';

export default class extends Controller {
  static targets = ['spotlight']
  connect() {
    const options = {
      glare: true,
      "max-glare": 0.4,
      max: 8,
      perspective: 2000,
      speed: 450,
      transition: true
    }
    this.spotlightTargets.forEach(target => VanillaTilt.init(target, options))
  }
}
