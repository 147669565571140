import { Controller } from "@hotwired/stimulus"
import { isVisible } from "../../../global/utils";

export default class extends Controller {
  static targets = [
    "homePage",
    "page",
  ]

  open() {
    this.element.classList.add("nav-open");
  }

  close() {
    this.element.classList.remove("nav-open");
  }

  goHome(event) {
    event.preventDefault();
    let page = this.currentPage();
    page.classList.add("animate__animated", "animate__slideOutRight", "animate__faster");
    this.homePageTarget.style.display = "";

    page.addEventListener('animationend', () => {
      page.classList.remove("animate__slideOutRight");
      page.style.display = "none";
    }, { once: true });
  }

  currentPage() {
    return this.pageTargets.find(r => isVisible(r));
  }

  showPage(event) {
    event.preventDefault();
    let pageId = event.currentTarget.dataset.pageId;
    let page = this.pageTargets.find(r => r.dataset.pageId == pageId);
    page.style.display = "";
    this.homePageTarget.classList.add("animate__animated", "animate__slideOutLeft", "animate__faster");
    this.homePageTarget.addEventListener('animationend', () => {
      this.homePageTarget.classList.remove("animate__slideOutLeft");
      this.homePageTarget.style.display = "none";
    }, { once: true });
  }

  teardown() {
    this.close();
  }
}
