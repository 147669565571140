// Turbo Drive causes issues with the Hubspot chat widget.
//
// This fixes those issues.
document.addEventListener('turbo:before-fetch-request', (event) => {
  const chatStyles = document.querySelector('#hs-messages-styles')

  // if we've not assigned that ID yet, then it's not data-turbo-permenant, and
  // we need to find the hubspot style tag, and make sure it persists between
  // page loads
  if(!chatStyles) {
    const styleTags = document.body.querySelectorAll("style[type='text/css']");
    for (const tag of styleTags) {
      if(tag.innerHTML.indexOf('hs-messages') != -1) {
        tag.id = "hs-messages-styles"
        tag.setAttribute('data-turbo-permanent', '')
      }
    }
  }


  // this is needed for hubspot chat widget to load correctly between turbo page loads. See Issue #6944 for more details
  event.detail.fetchOptions.headers['Turbo'] = true;

})

document.addEventListener("turbo:load", function(){
  window.hsConversationsSettings = {
    loadImmediately: false,
  }

  if(window.HubSpotConversations) {
    let widget = window.HubSpotConversations.widget

    widget.remove()
    widget.load();
  }

  window.hsConversationsOnReady = [
    function() {
      window.HubSpotConversations.widget.load();
    },
  ];
})
