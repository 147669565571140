import { Controller } from "@hotwired/stimulus";
import copy from "copy-to-clipboard";

export default class extends Controller {
  static values = {
    copyUrl: String,
  };

  copyUrl(event) {
    event.preventDefault()
    copy(this.copyUrlValue)
    event.target.classList.add("animate__animated", "animate__flash")
    this.element.addEventListener('animationend', () => {
      event.target.classList.remove("animate__animated", "animate__flash")
    })
  }
}
