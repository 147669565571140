import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "button",
    "multiFields",
    "multiField",
    "otherField",
    "mostUsedField",
    "mostUsedSelect",
  ];

  connect() {
    this.setSelectionStates();
  }

  setSelectionStates() {
    let otherToggled = false;

    this.buttonTargets.forEach((el) => {
      el.classList.remove("selected");
    });

    Array.from(this.mostUsedSelectTarget.options).forEach((el) => {
      if (el.value) {
        el.hidden = true;
        el.disabled = true;
      }
    })

    this.multiFieldTargets.forEach((el) => {
      this.buttonTargets.forEach((el2) => {

        Array.from(this.mostUsedSelectTarget.options).forEach((el3) => {
          if (el3.value == el.value) {
            el3.hidden = false;
            el3.disabled = false;
          }
        });

        if (el2.dataset.authProviderName == el.value) {
          el2.classList.add("selected");
          if (el2.dataset.authProviderName == "Other") {
            this.otherFieldTarget.style.display = "";
            this.otherFieldTarget.style.visibility = "visible";
            otherToggled = true;
          }
        }
      })
    });

    if (!otherToggled) {
      this.otherFieldTarget.style.display = "none";
      this.otherFieldTarget.style.visibility = "hidden";
    }

    if (this.multiFieldTargets.length > 1) {
      this.showMostUsed()
    } else {
      this.hideMostUsed()
    }
  }

  togglePick(event) {
    if (event.currentTarget.classList.contains("selected")) {
      this.removeValue(event.currentTarget.dataset.authProviderName)
    } else {
      this.addValue(event.currentTarget.dataset.authProviderName)
    }

    this.setSelectionStates();
  }

  addValue(value) {
    const el = document.createElement('input');
    el.setAttribute("multiple", "multiple");
    el.setAttribute("value", value);
    el.setAttribute("data-auth-provider-surveys--picker-target", "multiField");
    el.setAttribute("type", "hidden");
    el.setAttribute("name", `marketing_auth_provider_survey[auth_providers_used][]`);
    el.setAttribute("id", `marketing_auth_provider_survey_auth_providers_used`);

    this.multiFieldsTarget.appendChild(el);
  }

  showMostUsed() {
    this.mostUsedFieldTarget.style.display = "";
    this.mostUsedFieldTarget.style.visibility = "visible";
  }

  hideMostUsed() {
    this.mostUsedFieldTarget.style.display = "none";
    this.mostUsedFieldTarget.style.visibility = "hidden";
  }

  removeValue(value) {
    this.multiFieldTargets.forEach((el) => {
      if (el.value == value) {
        el.remove();
      }
    });
  }
}
