import { Controller } from "@hotwired/stimulus"
import isVisible from "true-visibility"

export default class extends Controller {
  static interval
  static targets = ["contentItem", "menuItem"]
  static values = {
    autoStartTimer: { type: Boolean, default: true },
    interval: { type: Number, default: 6500 },
    scrollActiveIntoView: { type: Boolean, default: false }
  }

  connect() {
    this.activate(this.menuItemTarget)
    if (this.autoStartTimerValue) {
      this.startTimer()
    }
  }

  startTimer() {
    if(this.interval) {
      return
    }

    this.interval = setInterval(() => {
      let visibleMenuItems = this.menuItemTargets.filter(check => isVisible(check))
      let currentEl = visibleMenuItems.find(element => element.classList.contains("active"))
      let currentElIndex = Array.from(visibleMenuItems).indexOf(currentEl);
      let el = visibleMenuItems[currentElIndex + 1] || this.menuItemTargets[0]

      this.activate(el, true)
    }, this.intervalValue)
  }

  disconnect() {
    clearInterval(this.interval)
  }

  activate(target, scroll=false) {
    const id = target.dataset.carouselMenuIdParam
    this.menuItemTargets.forEach(target => target.classList.remove("active"))
    target.classList.add("active")
    this.contentItemTargets.forEach(target => target.classList.remove("active"))
    this.contentItemTargets.find(element => element.dataset.carouselMenuId == id).classList.add("active")

    // This code is highly customized for the visibility use-case question menu.
    // it will need a refactor if we plan to use it elsewhere.
    if (this.scrollActiveIntoViewValue && scroll) {
      target.parentElement.parentElement.scrollTo({top: 0, left: target.getBoundingClientRect().left - 40, behavior: "smooth"})
    }
  }

  manuallyActivate(event) {
    // If a user clicks the item then we don't want it to shift anymore
    clearInterval(this.interval)
    this.activate(event.currentTarget)
  }

  getElementIndex(element) {
    return Array.from(element.parentNode.children).indexOf(element);
  }
}
