import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import { debounce } from "lodash";

export default class extends Controller {
  static targets = ["input", "content", "modal", "clearButton"]

  connect() {
    this.element.addEventListener("keydown", e => {
      if (e.key === "Escape") {
        if (this.inputIsEmpty()) {
          this.hide();
        } else {
          this.clear();
        }
      }
    });

    this.modalTarget.addEventListener("click", e => {
      if (e.target === this.modalTarget) {
        this.hide();
      }
    });
  }

  show(event) {
    event.preventDefault();

    this.modalTarget.style.display = "block";
    this.inputTarget.focus();
  }

  hide() {
    this.modalTarget.style.display = "none";
    this.clear()
  }

  clear() {
    this.inputTarget.value = "";
    this.contentTarget.innerHTML = "";
    this.inputTarget.focus();
    this.toggleClear();
  }

  search = debounce(() => {
    const timestamp = new Date().getTime();
    this.contentTarget.dataset.timestamp = timestamp;

    Rails.ajax({
      url: "/blog/search",
      type: "GET",
      dataType: "script",
      data: `q=${this.inputTarget.value}&timestamp=${timestamp}`,
    })
  }, 100)

  toggleClear() {
    if (this.inputIsEmpty()) {
      this.clearButtonTarget.style.display = "none";
    } else {
      this.clearButtonTarget.style.display = "inline-block";
    }
  }

  inputIsEmpty() {
    return this.inputTarget.value.length === 0;
  }
}
