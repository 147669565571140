import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["question"]

  toggle(event) {
    const questionTarget = event.currentTarget.parentNode
    questionTarget.classList.toggle("open")
    this.questionTargets.filter(item => item != questionTarget).forEach(target => target.classList.remove("open"))
  }
}
